<template>
  <div>
    <!-- 최초 분석만 있을 때 -->
    <div v-if="!record.prev" :class="[isReport ? 'divi singleImageContainer' : 'resultBox', 'recordRow']">
      <!-- 레포트인 경우 이미지와 텍스트 위치가 다르기 때문에 별도 처리 -->
      <template v-if="isReport">
        <!-- 좌측 이미지 -->
        <div class="imgBox singleImage reducedWidth">
          <div class="imgW taC reducedImage firstOnlyImage">
            <img v-if="record.firstSpine && record.firstSpine.spineImgUrl" :src="record.firstSpine.spineImgUrl" alt="최초 분석 척추 엑스레이 이미지" @error="onErrorImage($event)" />
          </div>
          <p class="date report consistent-size">{{ record.firstSpine?.pictureDtStr || '' }} (최초 분석)</p> <!-- 레포트 시 검정색 문구 -->
        </div>

        <!-- 우측 텍스트 -->
        <div class="txtList expandedText firstOnlyText expandedWidth">
          <div>
            <p class="tit">최초 분석 결과 <span class="gray stxt">(촬영일 : {{ record.firstSpine.pictureDtStr }})</span></p>
            <ul class="resultInfo">
              <li>
                <p class="leftTxt">Cobb's angle 1</p>
                <p class="rightTxt">{{ record.firstSpine.firstAngle }}˚ <span>({{ record.firstSpine.firstDirection }})</span></p>
              </li>
              <li v-if="record.firstSpine.secondAngle">
                <p class="leftTxt">Cobb's angle 2</p>
                <p class="rightTxt">{{ record.firstSpine.secondAngle }}˚ <span>({{ record.firstSpine.secondDirection }})</span></p>
              </li>
              <li v-if="record.firstSpine.thirdAngle">
                <p class="leftTxt">Cobb's angle 3</p>
                <p class="rightTxt">{{ record.firstSpine.thirdAngle }}˚ <span>({{ record.firstSpine.thirdDirection }})</span></p>
              </li>
            </ul>
          </div>
        </div>
      </template>

      <!-- 레포트가 아닌 경우 기존 구조 -->
      <template v-else>
        <!-- 좌측 이미지 -->
        <div class="imgBox">
          <div class="imgW taC">
            <img v-if="record.firstSpine && record.firstSpine.spineImgUrl" :src="record.firstSpine.spineImgUrl" alt="최초 분석 척추 엑스레이 이미지" @error="onErrorImage($event)" />
          </div>
          <p class="date consistent-size">{{ record.firstSpine?.pictureDtStr || '' }} (최초 분석)</p> <!-- 일반적인 경우 흰색 문구 -->
        </div>

        <!-- 우측 텍스트 -->
        <div class="txtList">
          <div>
            <p class="tit">최초 분석 결과 <span class="gray stxt">(촬영일 : {{ record.firstSpine.pictureDtStr }})</span></p>
            <ul class="resultInfo">
              <li>
                <p class="leftTxt">Cobb's angle 1</p>
                <p class="rightTxt">{{ record.firstSpine.firstAngle }}˚ <span>({{ record.firstSpine.firstDirection }})</span></p>
              </li>
              <li v-if="record.firstSpine.secondAngle">
                <p class="leftTxt">Cobb's angle 2</p>
                <p class="rightTxt">{{ record.firstSpine.secondAngle }}˚ <span>({{ record.firstSpine.secondDirection }})</span></p>
              </li>
              <li v-if="record.firstSpine.thirdAngle">
                <p class="leftTxt">Cobb's angle 3</p>
                <p class="rightTxt">{{ record.firstSpine.thirdAngle }}˚ <span>({{ record.firstSpine.thirdDirection }})</span></p>
              </li>
            </ul>
          </div>
        </div>
      </template>
    </div>

    <!-- 직전 분석과 최초 분석이 모두 있을 때 -->
    <div v-else :class="[isReport ? 'divi horizontalImages' : 'resultBox', 'recordRow']">
      <!-- 레포트인 경우 이미지와 텍스트 위치가 다르기 때문에 별도 처리 -->
      <template v-if="isReport">
        <div class="flexContainer">
          <!-- 좌측 이미지 -->
          <ul class="imgBox horizontalImages">
            <li>
              <div class="imgW taC reducedImage">
                <img v-if="record.firstSpine && record.firstSpine.spineImgUrl" :src="record.firstSpine.spineImgUrl" alt="최초 분석 척추 엑스레이 이미지" @error="onErrorImage($event)" />
              </div>
              <p class="date report consistent-size">{{ record.firstSpine?.pictureDtStr || '' }} (최초 분석)</p>
            </li>
            <li v-if="record.prev">
              <div class="imgW taC">
                <img v-if="record.prev && record.prev.spineImgUrl" :src="record.prev.spineImgUrl" alt="직전 척추 엑스레이 이미지" @error="onErrorImage($event)" />
              </div>
              <p class="date current consistent-size">{{ record.prev?.pictureDtStr || record.firstSpine?.pictureDtStr || '' }} (직전 분석)</p>
            </li>
          </ul>

          <!-- 우측 텍스트 -->
          <ul class="txtList">
            <li>
              <p class="tit">직전 분석 결과 <span class="gray stxt">(촬영일 : {{ record.prev?.pictureDtStr || record.firstSpine?.pictureDtStr || '' }})</span></p>
              <ul class="resultInfo">
                <li>
                  <p class="leftTxt"><span v-if="window.width > 1550">Cobb's </span>angle 1</p>
                  <p class="rightTxt">{{ record.prev?.firstAngle || record.firstSpine?.firstAngle }}˚ <span>({{ record.prev?.firstDirection || record.firstSpine?.firstDirection }})</span></p>
                </li>
                <li v-if="record.prev?.secondAngle || record.firstSpine?.secondAngle">
                  <p class="leftTxt"><span v-if="window.width > 1550">Cobb's </span>angle 2</p>
                  <p class="rightTxt">{{ record.prev?.secondAngle || record.firstSpine?.secondAngle }}˚ <span>({{ record.prev?.secondDirection || record.firstSpine?.secondDirection }})</span></p>
                </li>
                <li v-if="record.prev?.thirdAngle || record.firstSpine?.thirdAngle">
                  <p class="leftTxt"><span v-if="window.width > 1550">Cobb's </span>angle 3</p>
                  <p class="rightTxt">{{ record.prev?.thirdAngle || record.firstSpine?.thirdAngle }}˚ <span>({{ record.prev?.thirdDirection || record.firstSpine?.thirdDirection }})</span></p>
                </li>
              </ul>
            </li>
            <li>
              <p class="tit">최초 분석 결과 <span class="gray stxt">(촬영일 : {{ record.firstSpine.pictureDtStr }})</span></p>
              <ul class="resultInfo">
                <li>
                  <p class="leftTxt"><span v-if="window.width > 1550">Cobb's </span>angle 1</p>
                  <p class="rightTxt">{{ record.firstSpine.firstAngle }}˚ <span>({{ record.firstSpine.firstDirection }})</span></p>
                </li>
                <li v-if="record.firstSpine.secondAngle">
                  <p class="leftTxt"><span v-if="window.width > 1550">Cobb's </span>angle 2</p>
                  <p class="rightTxt">{{ record.firstSpine.secondAngle }}˚ <span>({{ record.firstSpine.secondDirection }})</span></p>
                </li>
                <li v-if="record.firstSpine.thirdAngle">
                  <p class="leftTxt"><span v-if="window.width > 1550">Cobb's </span>angle 3</p>
                  <p class="rightTxt">{{ record.firstSpine.thirdAngle }}˚ <span>({{ record.firstSpine.thirdDirection }})</span></p>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </template>

      <!-- 레포트가 아닌 경우 기존 구조 -->
      <template v-else>
        <!-- 좌측 이미지 -->
        <ul class="imgBox horizontalImages">
          <li>
            <div class="imgW taC">
              <img v-if="record.firstSpine && record.firstSpine.spineImgUrl" :src="record.firstSpine.spineImgUrl" alt="최초 분석 척추 엑스레이 이미지" @error="onErrorImage($event)" />
            </div>
            <p class="date consistent-size">{{ record.firstSpine?.pictureDtStr || '' }} (최초 분석)</p>
          </li>
          <li v-if="record.prev">
            <div class="imgW taC">
              <img v-if="record.prev && record.prev.spineImgUrl" :src="record.prev.spineImgUrl" alt="직전 척추 엑스레이 이미지" @error="onErrorImage($event)" />
            </div>
            <p class="date current consistent-size">{{ record.prev?.pictureDtStr || record.firstSpine?.pictureDtStr || '' }} (직전 분석)</p>
          </li>
        </ul>

        <!-- 우측 텍스트 -->
        <ul class="txtList">
          <li v-if="record.prev">
            <p class="tit">직전 분석 결과 <span class="gray stxt">(촬영일 : {{ record.prev?.pictureDtStr || record.firstSpine?.pictureDtStr || '' }})</span></p>
            <ul class="resultInfo">
              <li>
                <p class="leftTxt"><span v-if="window.width > 1550">Cobb's </span>angle 1</p>
                <p class="rightTxt">{{ record.prev?.firstAngle || record.firstSpine?.firstAngle }}˚ <span>({{ record.prev?.firstDirection || record.firstSpine?.firstDirection }})</span></p>
              </li>
              <li v-if="record.prev?.secondAngle || record.firstSpine?.secondAngle">
                <p class="leftTxt"><span v-if="window.width > 1550">Cobb's </span>angle 2</p>
                <p class="rightTxt">{{ record.prev?.secondAngle || record.firstSpine?.secondAngle }}˚ <span>({{ record.prev?.secondDirection || record.firstSpine?.secondDirection }})</span></p>
              </li>
              <li v-if="record.prev?.thirdAngle || record.firstSpine?.thirdAngle">
                <p class="leftTxt"><span v-if="window.width > 1550">Cobb's </span>angle 3</p>
                <p class="rightTxt">{{ record.prev?.thirdAngle || record.firstSpine?.thirdAngle }}˚ <span>({{ record.prev?.thirdDirection || record.firstSpine?.thirdDirection }})</span></p>
              </li>
            </ul>
          </li>
          <li>
            <p class="tit">최초 분석 결과 <span class="gray stxt">(촬영일 : {{ record.firstSpine.pictureDtStr }})</span></p>
            <ul class="resultInfo">
              <li>
                <p class="leftTxt"><span v-if="window.width > 1550">Cobb's </span>angle 1</p>
                <p class="rightTxt">{{ record.firstSpine.firstAngle }}˚ <span>({{ record.firstSpine.firstDirection }})</span></p>
              </li>
              <li v-if="record.firstSpine.secondAngle">
                <p class="leftTxt"><span v-if="window.width > 1550">Cobb's </span>angle 2</p>
                <p class="rightTxt">{{ record.firstSpine.secondAngle }}˚ <span>({{ record.firstSpine.secondDirection }})</span></p>
              </li>
              <li v-if="record.firstSpine.thirdAngle">
                <p class="leftTxt"><span v-if="window.width > 1550">Cobb's </span>angle 3</p>
                <p class="rightTxt">{{ record.firstSpine.thirdAngle }}˚ <span>({{ record.firstSpine.thirdDirection }})</span></p>
              </li>
            </ul>
          </li>
        </ul>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    record: {
      type: Object,
      default: () => ({})
    },
    isFirstRecord: {
      default: true
    },
    isReport: {
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/sass/app.scss";

.resultBox.recordRow {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 16px;
}

.imgBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 30%;
  flex-shrink: 0;
}

.imgBox.reducedWidth {
  width: 50%; /* 이미지 가로 영역을 줄임 */
}

.imgBox.horizontalImages {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.imgW {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imgW img {
  max-width: 100%;
  height: auto;
}

.date {
  text-align: center;
  font-size: 0.9rem;
  margin-top: 8px;
}

.date.consistent-size {
  font-size: 1.2rem;
}

.date.report {
  color: black;
}

.date:not(.report) {
  color: white;
}

.txtList {
  flex-grow: 1;
  border-radius: 4px;
  padding: 16px;
  color: white;
}

.txtList.expandedWidth {
  width: calc(100% - 50%); /* 텍스트 영역을 이미지 영역을 줄인만큼 넓힘 */
}

.resultInfo {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.resultInfo li {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
}

.leftTxt {
  font-weight: bold;
}

.rightTxt {
  text-align: right;
}

.tit {
  font-size: 1.2rem;
  display: flex;
  justify-content: space-between;
}

.stxt {
  font-size: 1rem;
  color: #888;
  text-align: right;
  margin-left: auto;
}

.firstVisitW {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}

.firstVisit {
  padding: 16px;
  text-align: center;
  font-size: 1.4rem;
  line-height: 1.3em;
}

.divi .singleImageContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  gap: 16px;
}

.divi .flexContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
}

.divi .txtList {
  color: black;
  width: 100%;
}

.divi .imgBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 200px;
}

.divi .imgBox.singleImage {
  flex-direction: column;
}

.divi .imgBox .imgW {
  height: 270px;
  background: #444;
  position: relative;
}

.divi .imgBox .imgW img {
  position: absolute;
  max-width: 50%;
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.divi .imgBox .date.current {
  font-size: 1.2rem;
  color: var(--color-point);
}

/* 레포트 및 최초 분석일 경우 */
.divi.singleImageContainer .imgBox.singleImage.reducedWidth {
  width: 30% !important; /* 이미지 영역의 너비를 절반으로 줄임 */
}

.divi.singleImageContainer .txtList.expandedText.firstOnlyText.expandedWidth {
  width: 50% !important; /* 텍스트 영역의 너비를 그만큼 넓힘 */
}

</style>
